import React from 'react';
import useStyles from './BoardMemberCard.styles';
import linkedinIcon from './../../../../../../content/assets/icons/linkedin.svg';
import { Hidden } from '@material-ui/core';

export interface IBoardMember {
  name: string;
  lastName: string;
  linkedin: string;
}

const BoardMemberCard = (props: IBoardMember) => {
  const { name, lastName, linkedin } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Hidden smDown>
        <div id="firstName">
          <strong>{name}</strong>
        </div>
        <div id="lastName">{lastName}</div>
      </Hidden>
      <Hidden mdUp>
        <div id="completeName">
          <span>
            <strong>{name}</strong>
          </span>{' '}
          <span>{lastName}</span>
        </div>
      </Hidden>
      {linkedin ? (
        <a id="linkedin" target="_blank" href={linkedin}>
          <img id="linkedin" src={linkedinIcon} alt="linkedin" />
        </a>
      ) : (
        <img id="linkedin" src={linkedinIcon} alt="linkedin" />
      )}
    </div>
  );
};

export default BoardMemberCard;
