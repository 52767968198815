import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../../../../content/assets/icons/AboutUs/BirdMobile.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      marginTop: 72,
      color: '#004F71',
      '& #nosotros-tag': {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '22px ',
        margin: 0,
        marginBottom: '13px',
      },
      '& h1': {
        fontWeight: 'normal',
        fontSize: '48px',
        margin: 0,
        marginBottom: '26px',
        lineHeight: '58px',
      },
      '& p': {
        fontSize: '16px',
        lineHeight: '25px',
        margin: '0 0 20px 0',
      },
    },
    textContainer: {
      padding: '90px 0 122px 0',
      '& #slogan1': {
        margin: '47px 0 15px 0',
        '& p': {
          margin: 0,
          lineHeight: '27px',
        },
      },
      '& #slogan2': {
        margin: 0,
        fontSize: '30px',
        lineHeight: '37px',
        fontWeight: 'bold',
      },
    },
    imgContainer: {
      margin: '90px 0',
      '& img': {
        width: 'auto',
        height: 'auto',
        maxWidth: '100%',
      },
    },
    [theme.breakpoints.down(1921)]: {
      root: {
        '& h1': {
          fontSize: '44px',
        },
      },
      container: {
        padding: '0 6%',
      },
    },
    [theme.breakpoints.down(769)]: {
      root: {
        backgroundOrigin: 'padding-box',
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        //backgroundPosition: '80% bottom',
        backgroundPosition: '80% -100px',
        '& h1': {
          marginBottom: '96px',
        },
      },
      textContainer: {
        padding: '25px 0 80px 0',
      },
    },

    [theme.breakpoints.down(501)]: {
      root: {
        backgroundImage: `url(${background})`,
        backgroundPosition: '20% -120px',
        '& h1': {
          width: '60%',
          fontSize: '30px',
          marginBottom: '96px',
          lineHeight: '35px',
        },
        '& p': {
          fontSize: '14px',
          lineHeight: '18px',
        },
      },
      container: {
        padding: '0 11.15%',
      },
      textContainer: {
        '& #slogan1': {
          margin: '47px 0 15px 0',
          '& p': {
            fontSize: '20px',
            lineHeight: '24px',
          },
        },
        '& #slogan2': {
          fontSize: '22px',
          lineHeight: '27px',
        },
      },
    },
  })
);
export default useStyles;
