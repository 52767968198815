import React from 'react';
import useStyles from './MemberCard.styles';

export interface IMemberCard {
  name: string;
  positions: string[];
  image: any;
}

const MemberCard = (props: IMemberCard) => {
  const { name, positions, image } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <img id="profileImg" src={image} alt={name} />
        <div id="cardName">{name}</div>
        {positions.map((position, idx) => (
          <div id="cardPositions" key={idx}>
            {position}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default MemberCard;
