import React from 'react';
import { Container, Grid } from '@material-ui/core';
import teamData from './Team.data';
import useStyles from './Team.styles';
import MemberCard from './MemberCard/MemberCard';

const Team = () => {
  const classes = useStyles();
  return (
    <section id="equipo" className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <h2 className={classes.title}>
          Nuestro <strong>equipo</strong>
        </h2>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={classes.membersContainer}
        >
          {teamData.map((member, idx) => (
            <Grid item key={idx} className={classes.itemContainer}>
              <MemberCard name={member.name} positions={member.positions} image={member.image} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
};

export default Team;
