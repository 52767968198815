import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../../../../content/assets/icons/teamBackground.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#004F71',
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center center',
      // minHeight: 1000,
      color: '#fff',
      /*paddingBottom: '6.2%',
      paddingTop: '3%',*/
      padding: '122px 0 42px 0',
    },
    title: {
      textAlign: 'left',
      fontSize: '48px',
      margin: 0,
      marginBottom: '80px',
      fontWeight: 'normal',
    },
    membersContainer: {
      maxWidth: 904,
      margin: '0 auto',
    },
    itemContainer: {
      width: '25.1%',
    },
    [theme.breakpoints.down(1921)]: {
      container: {
        padding: '0 6%',
      },
    },
    [theme.breakpoints.down(769)]: {
      root: {
        backgroundImage: 'none',
      },
      container: {
        padding: '0 11%',
      },
      membersContainer: {
        maxWidth: 559,
      },
      itemContainer: {
        width: '40%',
      },
      title: {
        fontSize: '30px',
        fontWeight: 'normal',
      },
    },
    [theme.breakpoints.down(501)]: {
      root: {
        padding: '80px 0 51px 0',
      },
      container: {
        padding: '0 11%',
      },
      membersContainer: {
        maxWidth: 279,
      },
      itemContainer: {
        width: '100%',
      },
      title: {
        fontSize: '30px',
        fontWeight: 'normal',
        marginBottom: '40px',
      },
    },
  })
);
export default useStyles;
