import { Container, Grid } from '@material-ui/core';
import boardData from './Board.data';
import useStyles from './Board.styles';
import BoardMemberCard from './BoardMemberCard/BoardMemberCard';
import React from 'react';

const Board = () => {
  const classes = useStyles();
  return (
    <section id="#directorio" className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <div className={classes.boardContainer}>
          <h2 className={classes.title}>Directorio</h2>
          <Grid container direction="row" justifyContent="space-between">
            {boardData.map((item, idx) => (
              <Grid item key={idx} className={classes.boardItem}>
                <BoardMemberCard
                  name={item.name}
                  lastName={item.lastName}
                  linkedin={item.linkedin}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </section>
  );
};

export default Board;
