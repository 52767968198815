import { Container, Grid } from '@material-ui/core';
import rippleImg from '../../../../../content/assets/icons/AboutUs/Ripple.svg';
import afexImg from '../../../../../content/assets/icons/AboutUs/Afex.png';
import React from 'react';
import useStyles from './Partners.styles';

const Partners = () => {
  const classes = useStyles();
  return (
    <section id="#partners" className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <h2>
          Partners y <strong>alianzas</strong> internacionales
        </h2>
        <Grid className={classes.imagesContainer} container>
          <Grid id="rippleImg" item xs={6}>
            <img src={rippleImg} alt="Ripple" />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Partners;
