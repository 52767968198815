import React from 'react';
import { graphql, PageProps } from 'gatsby';
import AboutUs from '../components/Landing/AboutUs/AboutUs';
import SEO from '../components/SEO';

const PAGE_TITLE = 'Conoce a CurrencyBird: Nuestra Misión y Origen';
const PAGE_DESCRIPTION =
  'Desde 2015, CurrencyBird revoluciona las transferencias de dinero desde Chile, ofreciendo transacciones rápidas, seguras y a precios justos.';

const AboutUsPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} withTemplate={false} />
      <AboutUs />
    </>
  );
};

export default AboutUsPage;

export const pageQuery = graphql`
  query AboutUsPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
