import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '22px',
      lineHeight: '27px',
      fontWeight: 'normal',
      textAlign: 'center',
      width: '210px',
      '& #lastName': {
        paddingBottom: '5%',
        borderBottom: '1px solid',
      },
      '& #linkedin': {
        paddingTop: '5%',
        display: 'flex',
        marginLeft: 'auto',
      },
    },
    [theme.breakpoints.down(769)]: {
      root: {
        width: '100%',
        paddingBottom: '50px',
        display: 'flex',
        '& #completeName': {
          borderBottom: '1px solid',
        },
        '& #linkedin': {
          paddingTop: 0,
          display: 'flex',
          marginLeft: 'auto',
        },
      },
    },
    [theme.breakpoints.down(501)]: {
      root: {
        paddingBottom: '50px',
        fontSize: '16px',
        width: '100%',
        display: 'flex',
        '& #completeName': {
          borderBottom: '1px solid',
        },
        '& #linkedin': {
          paddingTop: 0,
          display: 'flex',
          marginLeft: 'auto',
        },
      },
    },
  })
);
export default useStyles;
