const boardData = [
  {
    name: 'Teófilo',
    lastName: 'de la Cerda Soto',
    linkedin: 'https://cl.linkedin.com/in/teófilo-de-la-cerda-95780610b',
  },
  {
    name: 'Álvaro',
    lastName: 'Alliende Wielandt',
    linkedin: 'https://www.linkedin.com/in/alvaro-alliende-ab15685a/',
  },
  {
    name: 'Francisco',
    lastName: 'Mizgier Rojas',
    linkedin: 'https://cl.linkedin.com/in/franciscomizgier',
  },
  {
    name: 'Anton',
    lastName: 'Felmer Valdivielso',
    linkedin: 'https://www.linkedin.com/in/anton-felmer-634703b/',
  },
  {
    name: 'Cristián',
    lastName: 'de la Cerda Soto',
    linkedin: 'http://linkedin.com/in/cristian-de-la-cerda-s-79281a2a',
  },
];
export default boardData;
