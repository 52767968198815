import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '18px',
      textAlign: 'center',
      '& #profileImg': {
        width: '213px',
      },

      '& #cardName': {
        fontWeight: 'bold',
      },
      '& #cardPositions': {
        lineHeight: '22px',
      },
      marginBottom: '80px',
    },

    [theme.breakpoints.down(501)]: {
      root: {
        fontSize: '14px',
        lineHeight: '18px',
        '& #profileImg': {
          width: '180px',
        },
        '& #cardPositions': {
          lineHeight: '22px',
        },
        marginBottom: '29px',
      },
    },
  })
);
export default useStyles;
