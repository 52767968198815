import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      paddingTop: '122px',
      paddingBottom: '122px',
      color: '#004F71',
      '& h2': {
        fontSize: '30px',
        textAlign: 'left',
        fontWeight: 'normal',
        paddingBottom: '80px',
        margin: 0,
      },
      '& about-us-img': {
        width: '100%',
      },
    },
    imagesContainer: {
      width: '60%',
      margin: '0 auto',
      '& #rippleImg': {
        margin: 'auto',
        '& img': {
          marginRight: 'auto',
        },
      },
      '& #afexImg': {
        display: 'flex',
        '& img': {
          marginLeft: 'auto',
        },
      },
    },
    [theme.breakpoints.down(1921)]: {
      container: {
        padding: '0 6%',
      },
    },
    [theme.breakpoints.down(769)]: {
      root: {
        '& h2': {
          fontSize: '22px',
          lineHeight: '27px',
          textAlign: 'center',
        },
      },
      container: {
        padding: '0 11%',
      },
      imagesContainer: {
        width: '100%',
        margin: '0 auto',
        '& img': {
          width: '80%',
          margin: '0 auto',
        },
      },
    },
    [theme.breakpoints.down(501)]: {
      root: {
        '& h2': {
          fontSize: '22px',
          lineHeight: '27px',
          paddingBottom: '40px',
        },
        paddingTop: '80px',
        paddingBottom: '80px',
      },
      imagesContainer: {
        '& img': {
          width: '80%',
          margin: '0 auto',
        },
      },
    },
  })
);
export default useStyles;
