import daniela from '../../../../images/Members/daniela.png';
import teofilo from '../../../../images/Members/teofilo.png';
import pravesh from '../../../../images/Members/pravesh.png';
import jorge from '../../../../images/Members/jorge.png';
import alvaro from '../../../../images/Members/alvaro.png';
import leonardo from '../../../../images/Members/leonardo.png';
import nicolas from '../../../../images/Members/nicolas.png';
import yandry from '../../../../images/Members/yandry.png';
import yelen from '../../../../images/Members/yelen.png';
import dania from '../../../../images/Members/dania.png';
import yasmin from '../../../../images/Members/yasmin.png';
import matias from '../../../../images/Members/matias.png';
import vandrea from '../../../../images/Members/vandrea.png';
import catalina from '../../../../images/Members/catalina.png';
import empty from '../../../../images/Members/empty.png';
import david from '../../../../images/Members/david.png';
import fernando from '../../../../images/Members/fernando.png';
import mjose from '../../../../images/Members/mjose.png';

const teamData = [
  {
    name: 'Teófilo de la Cerda C.',
    positions: ['Gerente General'],
    image: teofilo,
  },
  {
    name: 'Daniela Gómez',
    positions: ['Abogada'],
    image: daniela,
  },
  {
    name: 'Pravesh Phalke',
    positions: ['Analista de compliance'],
    image: pravesh,
  },
  {
    name: 'Vandrea Meireele',
    positions: ['Analista de compliance'],
    image: vandrea,
  },
  {
    name: 'Álvaro Arriagada',
    positions: ['Tecnología'],
    image: alvaro,
  },
  {
    name: 'Leonardo Ibañez',
    positions: ['Tecnología'],
    image: leonardo,
  },
  {
    name: 'Dania de la Puente',
    positions: ['Tecnología'],
    image: dania,
  },
  {
    name: 'Fernando Vega',
    positions: ['Tecnología'],
    image: fernando,
  },
  {
    name: 'David Saji',
    positions: ['Tecnología'],
    image: david,
  },
  {
    name: 'Catalina Garrido',
    positions: ['Operaciones'],
    image: catalina,
  },
  {
    name: 'Jorge Carmona',
    positions: ['Operaciones'],
    image: jorge,
  },
  {
    name: 'Yasmin Obando',
    positions: ['Tesorería y contabilidad'],
    image: yasmin,
  },
  {
    name: 'Yandry Chivico',
    positions: ['Experiencia del usuario'],
    image: yandry,
  },
  {
    name: 'Yelen González',
    positions: ['Experiencia del usuario'],
    image: yelen,
  },
  {
    name: 'María José Delaporte',
    positions: ['Comunicaciones'],
    image: mjose,
  },
];

export default teamData;
