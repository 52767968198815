import React from 'react';
import bird from '../../../../../content/assets/icons/AboutUs/Bird.svg';
import { Grid, Container, Hidden } from '@material-ui/core';
import useStyles from './AboutUsIndex.styles';

const Index = () => {
  const classes = useStyles();
  return (
    <section id="#nosotros" className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Grid container>
          <Grid className={classes.textContainer} item xs={12} md={6}>
            <div id="nosotros-tag">Nosotros</div>
            <h1>
              Desde la Patagonia,
              <br /> al mundo{' '}
            </h1>
            <p>
              Nacimos como empresa en 2015, en Santiago de Chile, y en 2017 trasladamos nuestra
              oficina central a la ciudad de Puerto Varas.
            </p>
            <p>
              En el origen de CurrencyBird hay tres chilenos que, estudiando fuera del país, notamos
              lo complejo, lento y caro que era transferir dinero a través de las fronteras. Nos
              unimos con el objetivo de{' '}
              <strong>
                simplificar este proceso, reducir los intermediarios y alcanzar un precio justo para
                el usuario.
              </strong>
            </p>
            <p>
              En el camino nos dimos cuenta de que la naturaleza tenía la respuesta.
              <strong> Nuestro nombre es CurrencyBird </strong>porque la perfecta mecánica de las
              aves al volar nos inspiró en la construcción de nuestra plataforma. Las aves crean
              rutas inteligentes, livianas e infalibles para migrar de un lugar a otro; de la misma
              manera,{' '}
              <strong>
                nos propusimos buscar la mejor ruta para trasladar tú dinero a través de las
                fronteras.
              </strong>
            </p>
            <div id="slogan1">
              <p>Y encontramos la más eficiente.</p> <p>La más confiable.</p>
            </div>
            <p id="slogan2">¡La ruta perfecta!</p>
          </Grid>
          <Hidden smDown>
            <Grid className={classes.imgContainer} item md={6}>
              <img id="about-us-img" src={bird} />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </section>
  );
};

export default Index;
