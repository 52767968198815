import React from 'react';
import AboutUsIndexSection from './Index/AboutUsIndex';
import TeamSection from './Team/Team';
import BoardSection from './Board/Board';
import PartnersSection from './Partners/Partners';

const AboutUs = () => {
  return (
    <div className="landing-page">
      <AboutUsIndexSection />
      <TeamSection />
      <BoardSection />
      <PartnersSection />
    </div>
  );
};

export default AboutUs;
